<script>
  import { config } from "../store/configStore.js";
  import { locale } from "../store/languageStore.js";
  import { profile } from "../store/profileStore.js";
  import { params } from "../store/paramsStore.js";
  import { dataLayerSupport } from '../Datalayer.js';

  function fireDataLayer() {
    let userId = null;
    if ($profile.customerId) {
      userId = $profile.customerId;
    } else if ($params.openCloudUserId) {
      userId = $params.openCloudUserId;
    }

    if(window.dataLayer && userId) {
      dataLayerSupport(userId);
    }
  }

</script>

<style>
  .gh__support {
    background: var(--secondaryBtnBgColor) !important;
    color: var(--secondaryBtnTextColor) !important;
    font-weight: 300 !important;
    font-size: 11px !important;
    line-height: 16px !important;
    float: right !important;
    padding: 7px 0px !important;
    width: 57px !important;
    text-align: center !important;
  }
  @media screen and (min-width: 768px) {
    .gh__support {
      padding: 10px 0px !important;
      font-size: 14px !important;
      line-height: 20px !important;
      width: 77px !important;
    }
  }
</style>

<a
  href={$config['support'][$locale.currentLanguage]['url']}
  on:click={fireDataLayer}
  class="gh__support"
  target={$config['support'][$locale.currentLanguage]['target'] ? `_${$config['support'][$locale.currentLanguage]['target']}` : '_self'}>
  {$config['support'][$locale.currentLanguage]['name']}
</a>
