<script>
  import { onMount } from "svelte";
  import { config } from "../store/configStore.js";
  import { locale } from "../store/languageStore.js";
  import { profile } from "../store/profileStore.js";
  import { params } from "../store/paramsStore.js";
  import Arrow from "svelte-material-icons/ChevronDown.svelte";
  import AccountCircle from "svelte-material-icons/AccountCircle.svelte";
  import Logout from "svelte-material-icons/LogoutVariant.svelte";
  import { dataLayerLogout } from '../Datalayer.js';

  let size = "1em";
  let width = size;
  let height = size;
  let color = "#ffffff";

  function fireDataLayer() {
    let userId = null;
    if ($profile.customerId) {
      userId = $profile.customerId;
    } else if ($params.openCloudUserId) {
      userId = $params.openCloudUserId;
    }
    if(window.dataLayer && userId) {
      dataLayerLogout(userId);
    }
  }
</script>

<style>
  .gh__profileMenuContainer {
    float: right !important;
    border: 0 !important;
    height: 30px !important;
    background: transparent !important;
    position: relative !important;
  }

  button.gh__toggle {
    font-size: 11px !important;
    font-weight: 300 !important;
    color: white !important;
    height: 30px !important;
    border: none !important;
    background: transparent !important;
    text-align: center !important;
    outline-width: 0 !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
  }

  button.gh__toggle span {
    width: 100px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: block !important;
    font-size: 11px !important;
  }

  :global(.gh__toggle svg) {
    transition: all 200ms ease-in-out !important;
  }

  .gh__profileMenuContainer:hover ul.gh__profileMenuList {
    display: block !important;
  }

  :global(.gh__profileMenuContainer:hover .gh__toggle svg) {
    transform: rotate(180deg) !important;
  }
  ul.gh__profileMenuList {
    display: none !important;
    min-width: 226px !important;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute !important;
    color: #6c6c6c !important;
    list-style: none !important;
    right: 0 !important;
    text-align: center !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
  }

  ul.gh__profileMenuList li.gh__profileMenuItem {
    background: white !important;
    font-size: 14px !important;
    border-bottom: 1px solid #efefef !important;
    text-align: left !important;
    line-height: 15.5px !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    height: 59px !important;
    display: flex !important;
    align-items: center !important;
    position: relative !important;
    
  }
  ul.gh__profileMenuList li.gh__profileMenuItem > span {
    width: 100% !important;
    height: 100% !important;
    justify-content: space-between !important;
    display: flex !important;
    align-items: center !important;
    padding: 0 21px !important;
  }
  ul.gh__profileMenuList li.gh__profileMenuItem a {
    width: 100% !important;
    height: 100% !important;
    justify-content: space-between !important;
    display: flex !important;
    align-items: center !important;
    padding: 0 21px !important;
    border: none !important;
    background: transparent !important;
    outline-width: 0 !important;
    cursor: pointer !important;
    color: #6c6c6c !important;
  }

  :global(ul.gh__profileMenuList li.gh__profileMenuItem svg) {
    /* position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%); */
    margin-left: 10px !important;
  }

  ul.gh__profileMenuList li.gh__profileMenuItem:hover {
    background: rgb(247, 247, 247) !important;
  }

  img.gh__profileMenuItem-icon {
    max-width: 27px;
    max-height: 27px;
  }

  @media screen and (min-width: 768px) {
    .gh__profileMenuContainer {
      /* margin-right: 32px !important; */
      height: 40px !important;
      margin-left: 25px !important;
    }
    ul.gh__profileMenuList {
      min-width: 226px !important;
    }

    button.gh__toggle {
      font-size: 14px !important;
      height: 40px !important;
    }
    button.gh__toggle span {
      width: 120px !important;
      font-size: 14px !important;
    }
  }
</style>

<div class="gh__profileMenuContainer">
  {#if Object.keys($profile).length > 0}
    <button class="gh__toggle">
      {#if $profile.loginName}
        <span>{$profile.loginName}</span>
      {:else}
        <span />
      {/if}
      <Arrow {color} {size} {width} {height} />
    </button>
    <ul class="gh__profileMenuList">
      <li class="gh__profileMenuItem">
        <span>
          <span>
            {$profile.loginName}
            <br />
            ID: {$profile.cpUserId}
          </span>
          <AccountCircle color={'#CCCCCC'} size={'27px'} />
        </span>
      </li>
      {#each $config.account[$locale.currentLanguage] as item}
        <li class="gh__profileMenuItem">
          {#if item.url}
            <a href="{item.url}" target={item.target ? `_${item.target}` : '_self'} on:click={item.id == 'account_logout' ? fireDataLayer : {} }>
              <span>{item.name}</span>
              <span>
                {#if item.imageUrl}<img class="gh__profileMenuItem-icon" src={item.imageUrl} alt="icon"/>{/if}
                {#if item.id === 'account_logout'}
                  <Logout color={'#CCCCCC'} size={'27px'} />
                {/if}
              </span>
            </a>
          {:else}
            <span>
              {item.name}
              {#if item.imageUrl}<img class="gh__profileMenuItem-icon" src={item.imageUrl} alt="icon"/>{/if}
            </span>
          {/if}
        </li>
      {/each}
    </ul>
  {/if}
</div>
