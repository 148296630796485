<script>
  import { tweened } from "svelte/motion";
  import { cubicOut } from "svelte/easing";
  import AlertCircle from "svelte-material-icons/AlertCircle.svelte";

  import { locale } from "../store/languageStore.js";
  import { params } from "../store/paramsStore.js";
  import { maintenance } from "../store/maintenanceStore.js";

  let isOpen;
  let screenWidth;
  let notice;

  $: isOpen = $maintenance.isOpen;
  $: notice = $maintenance.notices.length > 0 ? $maintenance.notices[0] : null;

  const label = {
    en: "Notice",
    tc: "提示",
    sc: "提示"
  };

  const closeNotice = notice => {
    maintenance.toggle();
    if (notice.closeable) {
      maintenance.closeNotice(notice.id, $params.token);
    }
  };
</script>

<style>
  .gh__maintenanceMessage {
    width: 100% !important;
    background: #2342ff !important;
    color: white !important;
    transition: 200ms all ease-in !important;
  }

  .gh__maintenanceMessage .gh__maintenanceMessage_inner {
    max-width: 1200px !important;
    margin: auto !important;
    display: flex !important;
    align-items: flex-end !important;
    padding: 0px !important;
    flex-direction: column !important;
    position: relative !important;
  }

  .gh__maintenanceMessage
    .gh__maintenanceMessage_inner
    .gh__maintenanceMessage_title {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 0 !important;
    font-weight: bold !important;
    font-size: 14px !important;
    line-height: 21px !important;
    margin-right: 0px !important;
    min-width: 0px !important;
  }

  .gh__maintenanceMessage
    .gh__maintenanceMessage_inner
    .gh__maintenanceMessage_title
    > span {
    margin: 0 5px !important;
  }

  .gh__maintenanceMessage
    .gh__maintenanceMessage_inner
    .gh__maintenanceMessage_content {
    max-width: 900px !important;
    font-weight: 300 !important;
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .gh__maintenanceMessage
    .gh__maintenanceMessage_inner
    .gh__maintenanceMessage_content_hidden {
      display: none;
    }

  .gh__maintenanceMessage .gh__maintenanceMessage_closeBtn {
    display: block !important;
    width: 20px !important;
    height: 20px !important;
    background-color: #ffffff !important;
    position: absolute !important;
    border: none !important;
    top: 0 !important;
    right: 0 !important;
  }

  .gh__maintenanceMessage_closeBtn:before,
  .gh__maintenanceMessage_closeBtn:after {
    content: "" !important;
    position: absolute !important;
    width: 16px !important;
    height: 2px !important;
    background-color: #2e4bfd !important;
    top: 8px !important;
  }

  .gh__maintenanceMessage_closeBtn:before {
    -webkit-transform: rotate(45deg) !important;
    -moz-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
    left: 2px !important;
  }
  .gh__maintenanceMessage_closeBtn:after {
    -webkit-transform: rotate(-45deg) !important;
    -moz-transform: rotate(-45deg) !important;
    transform: rotate(-45deg) !important;
    right: 2px !important;
  }

  .gh__maintenanceMessage.open .gh__maintenanceMessage_title > span {
    margin: 0 5px 0 0 !important;
  }

  .gh__maintenanceMessage.open .gh__maintenanceMessage_closeBtn {
    display: block !important;
  }

  .gh__maintenanceMessage.open .gh__maintenanceMessage_inner {
    display: flex !important;
    align-items: flex-start !important;
    padding: 19px 30px !important;
  }

  .gh__maintenanceMessage.open
    .gh__maintenanceMessage_inner:not(:last-of-type) {
    padding-bottom: 0px !important;
  }

  @media screen and (min-width: 768px) {
    .gh__maintenanceMessage .gh__maintenanceMessage_inner {
      flex-direction: row !important;
      padding: 0 30px !important;
      align-items: center !important;
    }

    .gh__maintenanceMessage
      .gh__maintenanceMessage_inner
      .gh__maintenanceMessage_title {
      margin-right: 50px !important;
      min-width: 135px !important;
    }
    .gh__maintenanceMessage
      .gh__maintenanceMessage_inner
      .gh__maintenanceMessage_title
      > span {
      margin: 0 5px 0 0 !important;
    }

    .gh__maintenanceMessage.open .gh__maintenanceMessage_inner {
      align-items: center !important;
      height: calc(100% - 38px) !important;
    }
  }
</style>

<svelte:window bind:innerWidth={screenWidth} />
{#if notice}
  <div class="gh__maintenanceMessage {isOpen ? 'open' : ''}">
    {#if isOpen && $locale.currentLanguage}
      <div class="gh__maintenanceMessage_inner">
        <span class="gh__maintenanceMessage_title">
          <span class="v1">{@html notice.message[$locale.currentLanguage].title}</span>
          <AlertCircle color={'#ffffff'} size={'14px'} />
        </span>
        <span class="gh__maintenanceMessage_content v1">
          {@html notice.message[$locale.currentLanguage].text}
        </span>
        <span class="gh__maintenanceMessage_content_hidden"></span>
      </div>
      <button
        class="gh__maintenanceMessage_closeBtn"
        on:click={() => closeNotice(notice)} />
    {:else if screenWidth <= 767}
      <div
        class="gh__maintenanceMessage_inner"
        on:click={maintenance.toggle}>
        <span class="gh__maintenanceMessage_title">
          <AlertCircle color={'#ffffff'} size={'14px'} />
          <span>{label[$locale.currentLanguage]}</span>
        </span>
      </div>
    {/if}
  </div>
{/if}
