<script>
  import { onMount, afterUpdate } from "svelte";
  import cssVars from "svelte-css-vars";
  import { config } from "./store/configStore.js";
  import { locale } from "./store/languageStore.js";
  import { profile } from "./store/profileStore.js";
  import { params } from "./store/paramsStore.js";

  import MainMenu from "./components/MainMenu.svelte";
  import Logo from "./components/Logo.svelte";
  import SystemName from "./components/SystemName.svelte";
  import LanguageSwitcher from "./components/LanguageSwitcher.svelte";
  import SupportButton from "./components/SupportButton.svelte";
  import ProfileMenu from "./components/ProfileMenu.svelte";
  import MaintenanceMessage from "./components/MaintenanceMessage.svelte";
  import MaintenanceMessageBtn from "./components/MaintenanceMessageBtn.svelte";
  import { dataLayerLogin } from './Datalayer.js';

  let message = {
    en: "You are using login as user",
    tc: "你正在使用管理員登入",
    sc: "你正在使用管理员登入"
  };

  const style = $config.style;

  $: styleVars = {
    headerBgColor: style.headerBgColor,
    themeColor: style.themeColor,
    inHouseAdColor: style.inHouseAdColor,
    primaryTextColor: style.text.primary.color,
    secondaryTextColor: style.text.secondary.color,
    primaryBtnBgColor: style.button.primary.bgColor,
    primaryBtnTextColor: style.button.primary.color,
    secondaryBtnBgColor: style.button.secondary.bgColor,
    secondaryBtnTextColor: style.button.secondary.color
  };

  let dt = false;
  afterUpdate(() => {
    let userId = null;
    if ($profile.customerId) {
        userId = $profile.customerId;
    } else if ($params.openCloudUserId) {
        userId = $params.openCloudUserId;
    }
    console.log(userId);
    if(!dt && window.dataLayer && userId){
        console.log('login');
        dataLayerLogin(userId);
        dt = true;
    }
  });
</script>

<style>
  :global(a) {
    text-decoration: none !important;
  }
  .gh {
    position: fixed !important;
    width: 100% !important;
    z-index: 9999999999 !important;
    top: 0 !important;
  }
  .gh__navbar {
    background: var(--headerBgColor) !important;
    height: 30px !important;
    position: relative !important;
    /* margin-bottom: 40px; */
  }
  .gh__navbar * {
    z-index: 9999999999 !important;
  }
  div.gh__loginAsUser {
    text-align: center !important;
    background: #ff0000 !important;
    width: 100% !important;
    z-index: 9999 !important;
  }
  div.gh__loginAsUser > p {
    margin: 0 !important;
    font-size: 13px !important;
    color: #ffffff !important;
    text-align: center !important;
  }
  div.gh__rightMenu {
    position: absolute;
    top: 0;
    right: 0;
  }

  @media screen and (min-width: 768px) {
    .gh__navbar {
      background: var(--themeColor) !important;
      height: 40px !important;
      margin-bottom: 0;
    }
    div.gh__rightMenu {
    position: relative;
  }
    div.gh__loginAsUser {
        position: relative !important;
        display: flex !important;
        justify-content: center !important;
        top: -2px !important;
    }
  }
</style>

<div class="gh">
  <MaintenanceMessage />
  <div class="gh__navbar" use:cssVars={styleVars}>
    {#if $locale.currentLanguage.length > 0}
      <Logo />
      <SystemName />
      <MainMenu />
      <div class="gh__rightMenu">
        <SupportButton />
        <LanguageSwitcher />
        <ProfileMenu />
        <MaintenanceMessageBtn />
      </div>
    {/if}
  </div>
  {#if $profile && $profile.loginByAdmin && $locale.currentLanguage.length > 0}
    <div class="gh__loginAsUser">
      <p>{message[$locale.currentLanguage]}</p>
    </div>
  {/if}
</div>
