<script>
  import { onMount } from "svelte";
  import { config } from "../store/configStore.js";
  import { profile } from "../store/profileStore.js";
  import { params } from "../store/paramsStore.js";
  import { fade } from "svelte/transition";
  import { locale } from "../store/languageStore.js";
  import Arrow from "svelte-material-icons/ArrowRight.svelte";
  import ArrowRight from "svelte-material-icons/ChevronRight.svelte";
  import { dataLayerMenuList } from "../Datalayer.js";

  let width = "3em";
  let height = "1em";
  let color = "#ffffff";

  let slideIndex = 0;
  let ad = [];
  let x = 0;

  let previousElm = "";
  let click = 0;

  function toggleMenuList(event) {
    if (document.querySelector(".gh__mainMenu")) {
      const matches = (el, selector) =>
        (
          el.matches ||
          el.matchesSelector ||
          el.msMatchesSelector ||
          el.mozMatchesSelector ||
          el.webkitMatchesSelector ||
          el.oMatchesSelector
        ).call(el, selector);
      const result = matches(event.target, '[class*="gh__subMenuContainer"] *');

      if (click == 0) {
        previousElm = event.target.id;
        click++;
      }

      if (!result && previousElm == event.target.id) {
        event.target
          .querySelector(".gh__subMenuContainer")
          .classList.toggle("activeTab");
      } else {
        let id = "#" + previousElm + " > " + ".gh__subMenuContainer";
        document.querySelectorAll(id)[0].classList.remove("activeTab");
        event.target
          .querySelector(".gh__subMenuContainer")
          .classList.add("activeTab");
        previousElm = event.target.id;
      }
    }
  }

  function closeMenuOnTab(event) {
    if (document.querySelector(".gh__mainMenu")) {
      const matches = (el, selector) =>
        (
          el.matches ||
          el.matchesSelector ||
          el.msMatchesSelector ||
          el.mozMatchesSelector ||
          el.webkitMatchesSelector ||
          el.oMatchesSelector
        ).call(el, selector);
      const result = matches(
        event.target,
        '[class*="gh__subMenuContainer"] *, .gh__mainMenuTab'
      );
      if (!result) {
        const elements = document.querySelectorAll(".gh__subMenuContainer");
        Array.prototype.forEach.call(elements, (el, i) => {
          if (el.classList) {
            el.classList.remove("activeTab");
          }
        });
      }
    }
  }

  function fireDataLayer(item) {
    let event = "";
    let userId = null;

    if ($profile.customerId) {
      userId = $profile.customerId;
    } else if ($params.openCloudUserId) {
      userId = $params.openCloudUserId;
    }

    switch (item) {
      case "top_service":
        event = "CustomerCP-Listing-Service";
        break;

      case "top_billing":
        event = "CustomerCP-Listing-Billing";
        break;

      case "top_setting":
        event = "CustomerCP-Listing-Setting";
        break;

      default:
        break;
    }

    if (window.dataLayer && event.length > 0 && userId) {
      dataLayerMenuList(event, userId);
    }
  }

  onMount(() => {
    $config["mainMenus"][$locale.currentLanguage]["items"].forEach((item) => {
      if (item.inhouseAdvertisement) {
        ad = item.inhouseAdvertisement;
      }
    });

    let interval;
    let activeAd = 0;

    ad.map((item) => {
      if (item.type === "active") {
        activeAd++;
      }
    });

    if (ad && ad.length > 1 && activeAd > 1) {
      interval = setInterval(() => {
        slideIndex >= ad.length - 1 ? (slideIndex = 0) : slideIndex++;
      }, 3000);
    }

    const serviceElmt = document.getElementById("gh__mainMenuTab--top_service");
    const billingElmt = document.getElementById("gh__mainMenuTab--top_billing");

    if (serviceElmt) {
      document
        .getElementById("gh__mainMenuTab--top_service")
        .addEventListener("click", toggleMenuList);
    }

    if (billingElmt) {
      document
        .getElementById("gh__mainMenuTab--top_billing")
        .addEventListener("click", toggleMenuList);
    }

    document.body.addEventListener("click", closeMenuOnTab);

    return () => {
      clearInterval(interval);
      if (serviceElmt) {
        document
          .getElementById("gh__mainMenuTab--top_service")
          .removeEventListener("click", toggleMenuList);
      }

      if (billingElmt) {
        document
          .getElementById("gh__mainMenuTab--top_billing")
          .removeEventListener("click", toggleMenuList);
      }

      document.body.removeEventListener("click", closeMenuOnTab);
    };
  });
</script>

<style>
  ul.gh__mainMenu {
    width: 100% !important;
    /* height: 40px !important; */
    background: #ffffff !important;
    display: flex !important;
    justify-content: space-between !important;
    list-style: none !important;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  }
  ul.gh__mainMenu > li {
    text-align: center !important;
    flex-basis: 0 !important;
    flex: 1 1 0px !important;
    line-height: 40px !important;
  }

  ul.gh__mainMenu > li:not(:last-of-type) {
    border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  ul.gh__mainMenu > li:after {
    position: absolute !important;
    content: "" !important;
    height: 30px !important;
    width: 1px !important;
    top: 5px !important;
    right: 0px !important;
    background: #ebebeb !important;
  }
  ul.gh__mainMenu > li:last-of-type:after {
    content: none !important;
  }
  a.gh__mainMenuItem {
    color: #9c9c9c !important;
    color: #6c6c6c !important;
    font-size: 13px !important;
    line-height: 19px !important;
    font-weight: 300 !important;
    flex-grow: 1 !important;
    text-align: center !important;
    position: relative !important;
    pointer-events: none !important;
  }
  a.gh__mainMenuItem.disabled {
    color: rgba(108, 108, 108, 0.3) !important;
  }
  div.gh__subMenuContainer {
    background: var(--themeColor) !important;
    display: none !important;
    width: 100% !important;
    position: absolute !important;
    left: 0 !important;
    top: 70px !important;
    z-index: 9999999 !important;
  }
  a.gh__subMenuServiceManage {
    padding: 16px 30px 12px 30px !important;
    display: flex !important;
    justify-content: space-between !important;
    position: relative !important;
    align-items: center !important;
  }
  a.gh__subMenuServiceManage::after,
  div.gh__subMenuListContainer::after {
    position: absolute !important;
    content: "" !important;
    width: 100% !important;
    height: 1px !important;
    background: rgba(255, 255, 255, 0.2) !important;
    left: 0 !important;
    bottom: 0 !important;
  }
  :global(a.gh__subMenuServiceManage > svg) {
    width: 50px !important;
  }
  a.gh__subMenuServiceManage > p {
    margin: 0 !important;
    color: var(--primaryTextColor) !important;
    font-size: 14px !important;
    line-height: 21px !important;
    font-weight: 300 !important;
  }
  div.gh__subMenuListContainer {
    column-count: 2;
    column-gap: 1em;
    display: inline-block;
    text-align: left;
  }
  .gh__subMenuContainer.activeTab {
    display: flex !important;
    flex-direction: column !important;
  }
  #gh__mainMenuTab--top_setting > .gh__mainMenuItem {
    pointer-events: auto !important;
  }
  div.gh__subMenuList {
    display: inline-block !important;
    margin: 0em 0em 1em !important;
    width: max-content !important;
  }
  div.gh__subMenuList > ul {
    padding: 0 !important;
    list-style: none !important;
    margin: 0 !important;
  }
  p.gh__subMenuListHeader {
    color: rgba(255, 255, 255, 0.2) !important;
    font-size: 14px !important;
    line-height: 21px !important;
    margin: 21px 0 0 0 !important;
    padding: 0 30px !important;
    text-align: left !important;
    font-weight: 300 !important;
  }
  li.gh__subMenuItem a {
    padding: 8px 30px 8px 30px !important;
    text-align: left !important;
    display: block !important;
  }
  li.gh__subMenuItem > a {
    font-size: 14px !important;
    line-height: 21px !important;
    color: var(--primaryTextColor) !important;
    font-weight: 300 !important;
  }
  li.gh__subMenuItem > a.gh__subMenuItem-disabled {
    color: rgba(156, 156, 156, 0.2) !important;
  }
  div.gh__subMenuListAdContainer {
    padding: 0 30px !important;
    position: relative !important;
  }
  div.gh__subMenuListAd {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background: var(--themeColor) !important;
    padding: 17px 30px !important;
  }
  div.gh__subMenuListAd::after {
    position: absolute !important;
    content: "" !important;
    width: 100% !important;
    height: 1px !important;
    background: rgba(255, 255, 255, 0.2) !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
  }
  div.gh__subMenuListAd:last-of-type::after {
    content: none !important;
  }
  div.gh__subMenuListAd > p {
    font-size: 14px !important;
    line-height: 21px !important;
    text-align: left !important;
    margin: 0 !important;
    flex-basis: 60% !important;
  }

  div.gh__subMenuListAd > div {
    flex-basis: 40% !important;
    display: block !important;
    text-align: right !important;
  }

  span.gh__subMenuListAdTitle {
    display: block !important;
    color: var(--secondaryTextColor) !important;
  }
  span.gh__subMenuListAdText {
    display: block !important;
    color: var(--primaryTextColor) !important;
  }
  a.gh__subMenuListAdBtn {
    background: var(--secondaryBtnBgColor) !important;
    padding: 7px 11px !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: var(--secondaryBtnTextColor) !important;
    width: 120px !important;
    display: inline-block !important;
    margin-left: 5px !important;
    text-align: center !important;
    box-sizing: border-box !important;
    white-space: nowrap !important;
  }
  a.gh__billingMenuItem {
    display: flex !important;
    justify-content: space-between !important;
    padding: 5px 10px 5px 30px !important;
    font-size: 14px !important;
    align-items: center;
  }
  a.gh__billingMenuItem > span {
    color: #ffffff !important;
  }
  @media screen and (min-width: 768px) {
    ul.gh__mainMenu {
      width: unset !important;
      height: unset !important;
      position: unset !important;
      background: transparent !important;
      font-size: 14px !important;
      line-height: 21px !important;
      float: left !important;
      box-shadow: none !important;
    }
    ul.gh__mainMenu > li {
      padding: 0px 15px !important;
      margin: 0px !important;
      position: relative !important;
    }

    ul.gh__mainMenu > li:not(:last-of-type) {
      border-right: 0px !important;
    }

    ul.gh__mainMenu > li:after {
      content: none !important;
    }
    a.gh__mainMenuItem {
      font-size: 14px !important;
      line-height: 21px !important;
      color: var(--primaryTextColor) !important;
      text-align: left !important;
      border: none;
      pointer-events: auto !important;
      white-space: nowrap;
    }
    a.gh__mainMenuItem.disabled {
      color: rgba(255, 255, 255, 0.2) !important;
    }
    ul.gh__mainMenu li:hover div.gh__subMenuContainer {
      display: block !important;
      position: absolute !important;
      top: 40px !important;
      width: 670px !important;
    }
    ul.gh__mainMenu li:hover div.gh__subMenuContainer.billing {
      width: 200px !important;
    }
    a.gh__billingMenuItem {
      padding: 11px 0 11px 13px !important;
    }
    a.gh__billingMenuItem:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        #2a388f !important;
    }
    a.gh__billingMenuItem:hover span {
      text-decoration: underline !important;
    }
    div.gh__subMenuListContainer {
      margin-top: 0 !important;
      flex-direction: row !important;
      display: flex !important;
      flex-wrap: wrap !important;
      column-count: 4 !important;
    }
    div.gh__subMenuList {
      flex-basis: unset !important;
    }
    p.gh__subMenuListHeader {
      padding: 0 20px !important;
    }
    li.gh__subMenuItem a {
      padding: 5px 20px 5px 20px !important;
    }
    a.gh__subMenuServiceManage {
      display: none !important;
    }
    div.gh__subMenuListAdContainer {
      background: var(--inHouseAdColor) !important;
    }

    div.gh__subMenuListAd {
      position: relative !important;
      background: unset !important;
      padding: 17px 0 !important;
    }
    div.gh__subMenuListAd > p {
      flex-basis: initial !important;
    }
    div.gh__subMenuListAd > div {
      flex-basis: initial !important;
    }
  }
</style>

<svelte:window bind:innerWidth={x} />

<ul class="gh__mainMenu">
  {#each $config['mainMenus'][$locale.currentLanguage]['items'] as item}
    <li id="gh__mainMenuTab--{item.id}" class="gh__mainMenuTab">
      {#if x < 768}
        <!-- mobile view -->
        <a
          href={item.type === 'disabled' ? 'javascript:void(0)' : item.children ? 'javascript:void(0)' : item.url}
          class="gh__mainMenuItem {item.type}"
          target={item.type === 'disabled' ? '_self' : item.target ? `_${item.target}` : '_self'}
          on:click={() => {
            fireDataLayer(item.id);
          }}>
          {item.name}
        </a>
        <div class="gh__subMenuContainer" out:fade={{ duration: 0 }}>
          {#if item.mobileNavText}
            <a
              href={item.url}
              class="gh__subMenuServiceManage"
              target={item.target ? `_${item.target}` : '_self'}>
              <p>{item.mobileNavText}</p>
              <Arrow {color} {width} {height} />
            </a>
          {/if}
          {#if item.id === 'top_billing' && item.children}
            {#each item.children as invoiceList}
              <a
                href={invoiceList.url}
                class="gh__billingMenuItem"
                target={invoiceList.target ? `_${invoiceList.target}` : '_self'}>
                <span>{invoiceList.name}</span>
                <ArrowRight {color} {width} {height} />
              </a>
            {/each}
          {:else if item.children}
            <div class="gh__subMenuListContainer">
              {#each item.children as menuitemlist}
                <div class="gh__subMenuList">
                  <p class="gh__subMenuListHeader">{menuitemlist.heading}</p>
                  <ul>
                    {#each menuitemlist.children as menuitem}
                      <li class="gh__subMenuItem">
                        <a
                          href={menuitem.url}
                          target={menuitem.target ? `_${menuitem.target}` : '_self'}>{menuitem.name}</a>
                      </li>
                    {/each}
                  </ul>
                </div>
              {/each}
            </div>
          {/if}
          {#if item.inhouseAdvertisement}
            <div class="gh__subMenuListAdContainer">
              {#each item.inhouseAdvertisement as ad, i}
                {#if item.inhouseAdvertisement.length > 1 && ad.type === 'active' && slideIndex === i}
                  <div class="gh__subMenuListAd">
                    <p>
                      <span class="gh__subMenuListAdTitle">{ad.heading}</span>
                      <span class="gh__subMenuListAdText">{ad.text}</span>
                    </p>
                    <div>
                      <a
                        class="gh__subMenuListAdBtn"
                        href={ad.button.url}
                        target={ad.button.target ? `_${ad.button.target}` : '_self'}>
                        {ad.button.text}
                      </a>
                    </div>
                  </div>
                {/if}
              {/each}
            </div>
          {/if}
        </div>
      {:else}
        <a
          href={item.type === 'disabled' ? 'javascript:void(0)' : item.url}
          class="gh__mainMenuItem {item.type}"
          target={item.type === 'disabled' ? '_self' : item.target ? `_${item.target}` : '_self'}
          on:click={() => {
            fireDataLayer(item.id);
          }}>
          {item.name}
        </a>
        <div
          class="gh__subMenuContainer {item.id === 'top_billing' ? 'billing' : ''}">
          {#if item.mobileNavText}
            <a
              href={item.url}
              class="gh__subMenuServiceManage"
              target={item.target ? `_${item.target}` : '_self'}>
              <p>{item.mobileNavText}</p>
              <Arrow {color} {width} {height} />
            </a>
          {/if}
          {#if item.id === 'top_billing' && item.children}
            {#each item.children as invoiceList}
              <a
                href={invoiceList.url}
                class="gh__billingMenuItem"
                target={invoiceList.target ? `_${invoiceList.target}` : '_self'}>
                <span>{invoiceList.name}</span>
                <ArrowRight {color} {width} {height} />
              </a>
            {/each}
          {:else if item.children}
            <div class="gh__subMenuListContainer">
              {#each item.children as menuitemlist}
                <div class="gh__subMenuList">
                  <p class="gh__subMenuListHeader">{menuitemlist.heading}</p>
                  <ul>
                    {#each menuitemlist.children as menuitem}
                      <li class="gh__subMenuItem">
                        <a href={menuitem.url}>{menuitem.name}</a>
                      </li>
                    {/each}
                  </ul>
                </div>
              {/each}
            </div>
          {/if}
          {#if item.inhouseAdvertisement}
            <div class="gh__subMenuListAdContainer">
              {#each item.inhouseAdvertisement as ad, i}
                {#if ad.type === 'active'}
                  <div class="gh__subMenuListAd">
                    <p>
                      <span class="gh__subMenuListAdTitle">{ad.heading}</span>
                      <span class="gh__subMenuListAdText">{ad.text}</span>
                    </p>
                    <div>
                      <a
                        class="gh__subMenuListAdBtn"
                        href={ad.button.url}
                        target={ad.button.target ? `_${ad.button.target}` : '_self'}>
                        {ad.button.text}
                      </a>
                    </div>
                  </div>
                {/if}
              {/each}
            </div>
          {/if}
        </div>
      {/if}
    </li>
  {/each}
</ul>
