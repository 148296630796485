<script>
  import { config } from "../store/configStore.js";
  import { locale } from "../store/languageStore.js";
</script>

<style>
  a.gh__brandname {
    color: var(--primaryTextColor) !important;
    font-weight: 200 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    float: left !important;
    margin: 7px 0 5px 0 !important;
  }
  @media screen and (min-width: 768px) {
    a.gh__brandname {
      font-size: 15px !important;
      line-height: 22px !important;
      margin: 10px 0px !important;
      margin-right: 20px!important;
    }
  }
</style>

<a href="/" class="gh__brandname">
  {$config['branding'][$locale.currentLanguage]['text']}
</a>
