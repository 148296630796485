<script>
  import { onMount } from "svelte";
  import axios from "axios";
  import GlobalHeader from "./GlobalHeader.svelte";
  import { params } from "./store/paramsStore.js";
  import { profile } from "./store/profileStore.js";
  import { maintenance } from "./store/maintenanceStore.js";

  let profileData;
  $: profileData = $profile

  onMount(() => {
    profile.getUserInfo($params.token);
    maintenance.getNotice($params.token);
  });

  const supportedAPI = ["init", "render"];

  window.render = passInParams => {
    params.set(passInParams);
  };

  function render(passInParams) {
    params.set(passInParams);
  }

  function app(window) {
    // console.log("Global-Header starting");

    // set default configurations
    let configurations = {
      subsystemId: ""
    };

    // all methods that were called till now and stored in queue
    // needs to be called now
    let globalObject = window[window["Global-Header"]];
    let queue = globalObject.q;
    if (queue) {
      for (var i = 0; i < queue.length; i++) {
        if (queue[i][0].toLowerCase() === "init") {
          configurations = extendObject(configurations, queue[i][1]);
          // console.log("Global-Header started", configurations);
        } else apiHandler(queue[i][0], queue[i][1]);
      }
    }

    // override temporary (until the app loaded) handler
    // for widget's API calls
    globalObject = apiHandler;
    globalObject.configurations = configurations;
  }

  function apiHandler(api, params) {
    if (!api) throw Error("API method required");
    api = api.toLowerCase();

    if (supportedAPI.indexOf(api) === -1)
      throw Error(`Method ${api} is not supported`);

    // console.log(`Handling API call ${api}`, params);

    switch (api) {
      // TODO: add API implementation
      case "render":
        render(params);
        break;
      default:
        console.warn(`No handler defined for ${api}`);
    }
  }

  function extendObject(a, b) {
    for (var key in b) if (b.hasOwnProperty(key)) a[key] = b[key];
    return a;
  }

  app(window);
</script>

<style>
  :global(body) {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: "Heebo", sans-serif;
  }
</style>

<div>
  <GlobalHeader />
</div>
