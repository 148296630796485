<script>
  import { config } from "../store/configStore.js";
  import { locale } from "../store/languageStore.js";
</script>

<style>
  img.gh__logo {
    width: 21px !important;
    height: 14px !important;
    margin: 8px 7px !important;
    float: left !important;
  }
  @media screen and (min-width: 768px) {
    img.gh__logo {
      width: 26px !important;
      height: 20px !important;
      margin: 10px 12px !important;
    }
  }
</style>

<a href={`${process.env.CP_URL}/${$locale.currentLanguage == 'en' ? '?lang=en#!' : $locale.currentLanguage == 'tc' ? '?lang=zh_tw#!' : '?lang=zh_cn#!'}/`}>
  <img
    src={$config['branding'][$locale.currentLanguage]['logo']}
    alt="UDomain"
    class="gh__logo" />
</a>
