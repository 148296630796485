<script>
  import { onMount } from "svelte";
  import { locale } from "../store/languageStore.js";
  import { profile } from "../store/profileStore.js";
  import { params } from "../store/paramsStore.js";
  import { writable } from "svelte/store";
  import Arrow from "svelte-material-icons/ChevronDown.svelte";
  import { dataLayerLngSwitch } from '../Datalayer.js';

  let size = "1em";
  let width = size;
  let height = size;
  let color = "#ffffff";
  let rotate = "180deg";

  function hideLangList(event) {
    document
      .querySelector(".gh__languageSelectorList")
      .classList.remove("isOpen");
  }

  function toggleLangList(event) {
    document
      .querySelector(".gh__languageSelectorList")
      .classList.toggle("isOpen");
  }

  function openLangListOnHover(event) {
    if (document.querySelector(".gh__languageSelectorList") && window.innerWidth > 767) {
      const matches = (el, selector) =>
        (
          el.matches ||
          el.matchesSelector ||
          el.msMatchesSelector ||
          el.mozMatchesSelector ||
          el.webkitMatchesSelector ||
          el.oMatchesSelector
        ).call(el, selector);
      const result = matches(
        event.target,
        '[class*="gh__languageSelectorContainer"] *, [class*="gh__languageSelectorList"] *'
      );
      const elements = document.querySelectorAll(".gh__languageSelectorList");
      if (result) {
        Array.prototype.forEach.call(elements, (el, i) => {
          if (el.classList) {
            el.classList.add("isOpen");
          }
        });
      } else {
        Array.prototype.forEach.call(elements, (el, i) => {
          if (el.classList) {
            el.classList.remove("isOpen");
          }
        });
      }
    }
  }

  function fireDataLayer(lng) {
    let userId = null;
    if ($profile.customerId) {
      userId = $profile.customerId;
    } else if ($params.openCloudUserId) {
      userId = $params.openCloudUserId;
    }
    if(window.dataLayer && userId) {
      dataLayerLngSwitch(lng, userId);
    }
  }

  onMount(() => {
    document.body.addEventListener("mouseover", openLangListOnHover);

    return () => {
      document.body.removeEventListener("mouseover", openLangListOnHover);
    };
  });
</script>

<style>
  .gh__languageSelectorContainer {
    float: right !important;
    border: 0 !important;
    height: 30px !important;
    background: transparent !important;
    position: relative !important;
  }
  button.gh__languageCurrent {
    font-size: 11px !important;
    font-weight: 300 !important;
    color: var(--primaryTextColor) !important;
    height: 30px !important;
    border: none !important;
    background: transparent !important;
    text-align: center !important;
    outline-width: 0 !important;
    cursor: pointer !important;
    padding: 1px 15px !important;
    display: flex !important;
    align-items: center !important;
    text-transform: uppercase !important;
  }
  :global(.gh__languageCurrent svg) {
    transition: all 200ms ease-in-out !important;
  }
  .gh__languageSelectorContainer :global(.isOpen) {
    display: block !important;
  }

  :global(.gh__languageSelectorContainer:hover svg) {
    transform: rotate(180deg) !important;
  }

  ul.gh__languageSelectorList {
    display: none !important;
    width: 113px !important;
    padding: 0 !important;
    margin: 0 !important;
    position: absolute !important;
    color: #6c6c6c !important;
    list-style: none !important;
    right: 0 !important;
    text-align: center !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1) !important;
    z-index: 9999999 !important;
  }

  ul.gh__languageSelectorList li.gh__languageListOption {
    background: white !important;
    font-size: 14px !important;
    line-height: 21px !important;
    border-bottom: 1px solid #efefef !important;
  }
  ul.gh__languageSelectorList li.gh__languageListOption button {
    /* height: 30px !important; */
    border: none !important;
    background: transparent !important;
    outline-width: 0 !important;
    cursor: pointer !important;
    color: #6c6c6c !important;
    padding: 19px 21px !important;
    width: 100% !important;
    text-align: left !important;
  }
  ul.gh__languageSelectorList li.gh__languageListOption:hover {
    background: rgb(247, 247, 247) !important;
  }
  @media screen and (min-width: 768px) {
    .gh__languageSelectorContainer {
      /* margin-right: 32px !important; */
      height: 40px !important;
    }
    ul.gh__languageSelectorList {
      width: 226px !important;
    }
    button.gh__languageCurrent {
      font-size: 14px !important;
      height: 40px !important;
      padding: 1px 30px !important;
    }
  }
</style>

<div class="gh__languageSelectorContainer">
  <button class="gh__languageCurrent" on:click={event => {toggleLangList(event)}}>
    {$locale.currentLanguageText}
    <Arrow {color} {size} {width} {height} />
  </button>
  <ul class="gh__languageSelectorList">
    {#each $locale.availableLanguage as lng}
      {#if lng.active}
        <li class="gh__languageListOption">
          <button
            value={lng.language}
            on:click={event => {
              hideLangList(event);
              locale.languageSwitcherHandler(event);
              fireDataLayer(lng.language);
            }}>
            {lng.text}
          </button>
        </li>
      {/if}
    {/each}
  </ul>
</div>
