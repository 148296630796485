<script>
  import { tweened } from "svelte/motion";
  import { cubicOut } from "svelte/easing";
  import AlertCircle from "svelte-material-icons/AlertCircle.svelte";

  import { maintenance } from "../store/maintenanceStore.js";
  import { locale } from "../store/languageStore.js";

  let isOpen;
  let notice;

  const opacity = tweened(1, {
    duration: 300,
    easing: cubicOut
  });

  $: {
    isOpen = $maintenance.isOpen;
    if (!isOpen) {
      $opacity = 1;
    } else {
      $opacity = 0;
    }
  }

  $: notice = $maintenance.notices.length > 0 ? $maintenance.notices[0] : null;
  
  const label = {
    en: "Notice",
    tc: "提示",
    sc: "提示"
  };
</script>

<style>
  button {
    display: none !important;
    float: right !important;
    border: 0 !important;
    height: 27px !important;
    background: #2342ff !important;
    color: #ffffff !important;
    position: relative !important;
    padding: 5px 11px !important;
    align-items: center !important;
  }
  button span {
    margin: 0 5px !important;
  }

  @media screen and (min-width: 768px) {
    button.open {
      display: flex !important;
    }
  }
</style>

{#if notice}
  <button
    class={!isOpen ? 'open' : ''}
    on:click={maintenance.toggle}
    style="opacity: {$opacity}">
    <AlertCircle color={'#ffffff'} size={'14px'} />
    <span>{label[$locale.currentLanguage]}</span>
  </button>
{/if}
